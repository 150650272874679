var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('ValidationObserver',{ref:"validation-observer"},[_c('b-row',{},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Код","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Код","label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","size":"md","placeholder":"Введите"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Генерация промокода: Количество символов","label-for":"promosize"}},[_c('b-form-input',{attrs:{"type":"number","id":"promosize","placeholder":"Введите"},model:{value:(_vm.promoSize),callback:function ($$v) {_vm.promoSize=$$v},expression:"promoSize"}})],1),_c('b-button',{staticClass:"btn-success float-right mt-2",attrs:{"size":"sm"},on:{"click":_vm.generate}},[_vm._v(" Сгенерировать ")])],1),_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12","md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Скидка","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Скидка","label-for":"discount"}},[_c('b-form-input',{attrs:{"type":"number","id":"discount","size":"md","placeholder":"Введите"},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Минимальная сумма заказа"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Минимальная сумма заказа","label-for":"min_order_sum"}},[_c('b-form-input',{attrs:{"type":"number","id":"min_order_sum","size":"md","placeholder":"Введите"},model:{value:(_vm.min_order_sum),callback:function ($$v) {_vm.min_order_sum=$$v},expression:"min_order_sum"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Количество применений"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Количество применений","label-for":"min_order_sum"}},[_c('b-form-input',{attrs:{"type":"number","id":"uses","size":"md","placeholder":"Введите"},model:{value:(_vm.uses),callback:function ($$v) {_vm.uses=$$v},expression:"uses"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-col',{staticClass:"pt-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Действителен до"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Действителен до","label-for":"expires_at"}},[_c('b-form-datepicker',{attrs:{"size":"md","hide-header":"","id":"expires_at","locale":"ru"},model:{value:(_vm.expires_at),callback:function ($$v) {_vm.expires_at=$$v},expression:"expires_at"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-button',{staticClass:"btn-success float-right mt-2",attrs:{"disabled":_vm.isSaving},on:{"click":_vm.save}},[_vm._v(" Сохранить ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }